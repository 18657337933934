:root {
  --asu-blue: #00a3e0;
  --myasu-maroon: #8c1d40;
  --myasu-gold: #ffc627;
  --myasu-gray-95: #191919;
  --myasu-gray-80: #484848;
  --myasu-gray-70: #747474;
  --myasu-gray-30: #bfbfbf;
  --myasu-gray-20: #d0d0d0;
  --myasu-gray-10: #e8e8e8;
  --myasu-gray-05: #fafafa;
  --myasu-white: #fff;
  --myasu-error: #b72a2a;
  --myasu-warning: #bd4800;
  --myasu-success: #446d12;
  --myasu-info: #126877;
  --myasu-orange: #ff7f32;
  --myasu-green: #78be20;
  --myasu-blue: #00b0f3;
  --myasu-font-family: 'Arial',sans-serif;
  --text-color: #333333;
  --border-light-grey: #d7d7d7;
}

body {
    color: var(--text-color);
    font-family: "Poppins", sans-serif;
}

