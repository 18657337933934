.react-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* position: relative; */
}

.react-table {
  width: 100%;
  /* height: 450px; */
  /* overflow-y: auto; */
  border-collapse: collapse;
  background-color: #fff;
  font-size: 14px;
  /* margin-bottom: 1rem; */
}

.table-scroll-container {
  height: 420px;
  overflow-y: auto;
}

.react-table th,
.react-table td {
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #ccc;
  position: relative;
  vertical-align: top;
}

.react-table th {
  font-weight: bold;
  background-color: #f6f6f6;
}

.react-table thead {
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
  z-index: 2;
}

.column-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.column-header-title {
  display: flex;
  align-items: center;
}

.header-sort {
  display: flex;
}

.sort-icon {
  display: flex;
  align-items: center;
  margin-left: 8px;
  color: #aaa;
  transition: color 0.2s ease;
}

.filter-icon {
  display: flex;
  align-items: center;
  /* margin-left: 8px; */
  padding-left: 8px;
  padding-right: 8px;
  color: #aaa;
  transition: color 0.2s ease;
}

.header-sort:hover .sort-icon,
.filter-icon:hover,
.filter-icon.active {
  color: #333;
}

.column-header-filter {
  min-width: 160px;
}

/* .react-table th:hover .sort-icon {
  color: #333;
} */

.air-container .selected-row {
  /* background-color: #f6f6f6; */
  background-color: #fff;
}

/* .react-table th.asc .sort-icon {
      display: inline-block;
      margin-left: 5px;
      transform: translateY(-2px);
    }
    
    .react-table th.desc .sort-icon {
      display: inline-block;
      margin-left: 5px;
      transform: translateY(2px);
    } */

.pagination-options {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 14px;
}

.pagination-options span {
  margin-right: 0.5rem;
}

.pagination-options select {
  padding: 0.25rem;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #f6f6f6;
}

.pagination button {
  width: 50px;
  margin-left: 0.5rem;
  padding: 0.25rem 0.5rem;
  border: none;
  background-color: #f6f6f6;
  color: #333;
  cursor: pointer;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-details {
  font-size: 14px;
  width: 100px;
}

.accordian-text {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;;
}

.accordian-text .text-title {
  font-weight: bold;
  margin: 6px 5px;
  /* font-size: 14px; */
}

.accordian-text .text-data {
  margin: 2px 5px;
  /* font-size: 14px; */
}

.accordian-text.header {
  margin: 10px 10px 0;
  padding: 10px;
  border-radius: 5px;
  /* background-color: #e2e2e2; */
  border: 1px solid #ccc;
  color: #000;
  width: fit-content;
  font-size: 12px;

}

.accordian-text.body {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  /* background-color: #e2e2e2; */
  border: 1px solid #ccc;
  color: #000;
  width: fit-content;
  font-size: 14px;

}