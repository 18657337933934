.column-filter {
  position: relative;
}

.filter-box {
  margin-top: 3px;
  max-width: 140px;
}

.clear-icon {
  margin-left: 6px;
  color: #aaa;
  transition: color 0.2s ease;
}

.clear-icon:hover {
  color: #333;
}
