.dashboard-container {
  position: relative;
  /* width: 100%; */
  width: calc(100% - 70px);
  min-width: 100vh;
  margin-top: 60px;
  margin-left: 70px;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  /* width: calc(100vw - 70px); */
  width: 100%;
  height: 230px;
  background-image: url("../../images/background_img.png");
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;
  transform: scaleX(-1);
  z-index: -1;
}

div[class^="background-image"]:after {
  display: flex;
  content: "";
  height: 100%;
  width: 100%;
  background-image: linear-gradient(180deg, #19191900 0, #191919c9 100%);
  grid-column: 1 / span 3;
  grid-row: 1 / span 6;
}

.dashboard-welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 230px;
  width: 100%;
  padding: 20px 5%;
  min-width: 945px;
}

.dashboard-welcome h1 {
  font-weight: bold;
  margin-bottom: 0;
  color: #fff;
}

.section-title {
  font-size: 32px;
}

.dashboard-welcome p {
  margin-top: 25px;
  margin-bottom: 0px;
  max-width: 1100px;
  color: #fff;
  font-size: 20px;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
}

.dashboard-welcome .search-link {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

.dashboard-content {
  position: relative;
  /* z-index: 1; */
}

.dashboard-cards-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 155px;
  /* background-color: aqua; */
  /* margin: 20px; */
  /* Set margin to 20px if cards lowerpane is used */
  margin: 20px 20px 0;
  padding: 20px;
}

.dashboard-card {
  display: flex;
  flex-direction: column;
/* To remove dashboard-card lower pane   */
  /* height: 115px; */
  width: 22%;
  min-width: 200px;
  max-width: 320px;
  margin: 10px 20px;
  border-radius: 4px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
}

.card-upper-pane {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  padding: 10px;
}

.card-lower-pane {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  background-color: var(--myasu-maroon);
  padding: 4px 10px;
}

.card-upper-pane .card-data {
  align-items: center;
  margin-right: 20px;
}

/* .card-upper-pane .card-icon {
} */

.card-upper-pane .card-data span {
  font-size: 22px;
  color: var(--myasu-maroon);
  font-weight: bold;
}

.card-upper-pane .card-data p {
  font-size: 14px;
  margin-bottom: 0;
}

.card-upper-pane .card-icon svg {
  width: auto;
  height: 30px;
}

.card-lower-pane .change-text {
  font-size: 13px;
}

.card-lower-pane .change-value span {
  font-size: 16px;
  font-weight: bold;
  margin-right: 8px;
}

.card-lower-pane .change-value svg {
  width: auto;
  height: 15px;
}

.data-container-row-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 200; */
  /* background-color: aqua; */
  /* margin: 20px; */
  /* set margin to 20px if card lowerpane is used */
  margin: 0 20px;
  padding: 20px;
}

.popular-tags-container {
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 30%;
  min-width: 290px;
  /* max-width: 320px; */
  margin: 10px 20px;
  border-radius: 4px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
}

.map-container {
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 60%;
  min-width: 580px;
  /* max-width: 320px; */
  margin: 10px 20px;
  padding: 0px 30px 30px;
  border-radius: 4px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
}

.map-container .container-title {
  margin-left: 0;
}

.container-title {
  color: var(--myasu-maroon);
  font-size: 16px;
  font-weight: bold;
  margin: 20px 30px;
}

.data-container-row-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 450px; */
  /* background-color: aqua; */
  margin: 20px;
  padding: 20px;
}

.searches-by-month {
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 60%;
  min-width: 580px;
  /* max-width: 320px; */
  margin: 10px 20px;
  border-radius: 4px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
}

.interesting-finds {
  display: flex;
  flex-direction: column;
  height: 450px;
  width: 30%;
  min-width: 290px;
  /* max-width: 320px; */
  margin: 10px 20px;
  border-radius: 4px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
}

.data-container-row-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 450px; */
  /* background-color: aqua; */
  margin: 20px;
  padding: 20px;
}

.word-cloud {
  display: flex;
  flex-direction: column;
  height: 360px;
  width: 45%;
  min-width: 480px;
  /* max-width: 320px; */
  margin: 10px 20px;
  border-radius: 4px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
}

.project-timeline {
  display: flex;
  flex-direction: column;
  height: 360px;
  width: 45%;
  min-width: 480px;
  /* max-width: 320px; */
  margin: 10px 20px;
  border-radius: 4px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
}
