.admin-container {
  position: relative;
  width: 100%;
  min-width: 100vh;
  margin-top: 60px;
  margin-left: 70px;
}

.admin-container .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 230px;
  background-image: url("../../images/background_img.png");
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;
  transform: scaleX(-1);
  z-index: -1;
}

.admin-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 270px; */
  width: 100%;
  padding: 30px 5% 0;
}

.admin-title {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  justify-content: center;
  height: 200px;
}

.admin-title h1 {
  font-weight: bold;
  margin-left: 7px;
  margin-bottom: 0;
}

.admin-title p {
  margin-top: 25px;
  margin-bottom: 30px;
  max-width: 1100px;
  color: #fff;
  font-size: 20px;
}

.admin-container .table-container {
  display: flex;
  position: relative;
}

/* .admin-main-panel {
    background-color: #f6f6f6;
    max-height: 480px;
    width: 56%;
  }
  
  .admin-main-panel .column-header {
    flex-direction: row;
    justify-content: space-between;
  }
  
  .admin-side-panel {
    width: 44%;
    flex-direction: column;
    max-height: 480px;
    background-color: #f6f6f6;
  }
  
  .admin-side-panel .side-panel-data-container {
    min-height: 348px;
    margin: 0px 20px 20px;
    font-size: 14px;
  }
  
  .admin-side-panel .side-panel-title {
    margin: 10px 0 5px;
    font-size: 16px;
    font-weight: bold;
    padding: 5px 8px;
    max-height: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .admin-side-panel .no-data-text {
    height: 480px;
  } */

.entity-data {
  background-color: #f6f6f6;
  max-height: 480px;
  width: 36%;
  /* margin-right: 10px; */
  padding-right: 10px;
  /* overflow-y: auto; */
}

.entity-data.full-width {
  width: 100%;
}

.entity-view-data {
  width: 64%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 480px;
  padding-left: 10px;
  border-left: 1px solid #ccc;
  background-color: #f6f6f6;
  padding: 0 20px 20px 20px;
}

.groups-data {
  width: 50%;
  max-height: 480px;
  background-color: #f6f6f6;
  /* margin-right: 10px; */
  padding-right: 10px;
}

.entity-side-panel {
  width: 100%;
  display: flex;
  max-height: 480px;
  /* background-color: #f6f6f6; */
}

.features-data {
  width: 50%;
  background-color: #f6f6f6;
}

.no-data-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 400px;
  width: 100%;
}

.options-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.option-button {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 120px;
  margin: 12px 10px 10px;
  background-color: var(--myasu-maroon);
  border-radius: 20px;
}

button:hover.option-button {
  background-color: #a03152;
}

button.option-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.option-button.clear {
  background-color: var(--myasu-gray-20);
  color: #000;
}

button:hover.option-button.clear {
  background-color: var(--myasu-gray-30);
}

.admin-container .selected-item {
  color: var(--myasu-maroon);
  font-weight: bold;
  cursor: pointer;
}

.admin-container .selected-group {
  background-color: #f6f6f6;
  font-weight: bold;
  cursor: pointer;
}

.admin-container .row-item {
  /* padding: 8px; */
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.admin-container .row-item.selected {
  background-color: #f6f6f6;
}

.admin-container .angle-icon {
  margin-left: auto;
  color: #999;
}

.admin-container .angle-icon.selected {
  color: var(--myasu-maroon) !important;
}

.admin-container .content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 270px; */
  width: 100%;
  padding: 0 5% 50px;
}

.affiliation-select,
.subscription-select,
.date-select {
  width: 300px;
  /* height: 56px; */
  margin: 0 30px 20px !important;
}

.manage-title {
  margin-bottom: 40px;
}

.affiliation-select label.MuiInputLabel-root,
.subscription-select label.MuiInputLabel-root {
  margin-top: 0;
  padding-left: 0;
}

.affiliation-select label.MuiInputLabel-shrink,
.subscription-select label.MuiInputLabel-shrink {
  padding-left: 0;
  margin-top: -6px;
}

.plus-icon {
  font-size: 16px;
  cursor: pointer;
  margin-left: 8px;
  color: #aaa;
}

.plus-icon:hover {
  color: var(--myasu-maroon);
}

.dialog #expirationDate-label {
  padding-left: 0;
}

.dialog #expirationDate {
  height: 34px;
  padding-left: 14px;
}