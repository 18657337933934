.navbar {
  display: flex;
  justify-content: space-between;
  height: 60px;
  min-width: 800px;
  background-color: #f9f9f9;
  padding: 0 100px;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid var(--myasu-gray-20);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}

.navbar-brand {
  display: flex;
  align-items: center;
  /* min-width: 800px; */
}

.navbar-expand-md.navbar-light{
  min-width: 770px;
}

.logo {
  width: auto;
  height: 50px;
  object-fit: contain;
}

.nav-title {
  font-weight: bold;
  font-size: 22px;
  margin-left: 10px;
  color: var(--text-color);
}

.nav-items {
  display: flex;
  flex-direction: row;
}

.user-greeting {
  /* font-weight: bold; */
  margin-right: 10px;
}

.logout-button {
  color: red;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.navbar .nav-items {
  display: flex;
  align-items: center;
}

.separator {
  height: 40px;
  width: 1px;
  background-color: lightgray;
  margin: 0 10px;
}

.user-profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0 8px;
}

.user-profile.btn-secondary:hover,
.user-profile.btn-secondary:active,
.user-profile.btn-secondary:focus {
  background-color: transparent !important;
  box-shadow: none;
  border: none;
}

.user-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 14px;
}

.user-icon:hover {
  background-color: none;
}

.user-dropdown-menu.dropdown-menu {
  display: none;
}

.user-dropdown-menu.show {
  display: block;
}

.user-dropdown-menu {
  min-width: 200px;
}

.affiliate-name {
  font-size: 12px;
  /* font-weight: bold; */
  color: var(--myasu-maroon);
}

.user-dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  color: #333;
  transition: background-color 0.2s ease;
}

.user-dropdown-menu .dropdown-item:hover {
  background-color: #f1f1f1;
}

.user-dropdown-menu .dropdown-header {
  font-weight: bold;
  background-color: #f8f8f8;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
}

.user-dropdown-menu .dropdown-divider {
  margin: 0;
}

.dropdown-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #e9ecef;
}

.dropdown-user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.user-role-badge {
  background-color: var(--myasu-maroon);
  color: #ffffff;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
  /* text-transform: uppercase; */
}


@media (max-width: 850px) {
  .nav-title::before {
    content: "KAT";
  }

  .nav-title::after {
    content: none;
  }
  
  .nav-title[data-full] {
    visibility: hidden;
  }

  .nav-title[data-full]::before {
    visibility: visible;
    content: "KAT";
  }
}
