.about-container {
  position: relative;
  width: 100%;
  min-width: 100vh;
  margin-top: 60px;
  margin-left: 70px;
}

.about-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 230px;
  width: 100%;
  padding: 30px 5%;
  margin-bottom: 30px;
}

.about-container .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 230px;
  background-image: url("../../images/background_img.png");
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;
  z-index: -1;
  transform: scaleX(-1);
}

.about-title {
  /* margin-bottom: 20px; */
}

.about-title h1 {
  font-weight: bold;
  margin-left: 7px;
  margin-bottom: 0;
}

.about-title p {
  margin-top: 25px;
  margin-bottom: 0px;
  max-width: 1100px;
  color: #fff;
  font-size: 20px;
}

.about-header .title-description {
  /* margin-top: 10px; */
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 30px;
  color: #fff;
}

.about-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0px 5% 30px;
  margin-top: -15px;
}

.about-body .accordion {
  margin-bottom: 20px;
  border-left: 0.5rem solid var(--myasu-gold);
}

.about-body .accordion-header {
  background-color: #fff;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.about-body .accordion-header:hover {
  background-color: #f5f5f5;
}

.about-body .accordion-header h3 {
  margin: 0;
  margin-right: 5px;
  font-size: 20px;
  font-weight: bold;
}

.about-body .accordion-content {
  background-color: #fff;
  padding: 10px;
  display: none;
  font-size: 16px;
}

.about-body .accordion.active .accordion-content {
  display: block;
}

.about-body .accordion-icon {
  margin-left: auto;
  transition: transform 0.3s ease-in-out;
}

/* .accordion.active .accordion-icon {
  transform: rotate(180deg);
} */

.about-team {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  width: 100%;
  padding: 0px 5% 30px;
}

.about-team h2 {
  font-weight: bold;
  margin-left: 7px;
  margin-bottom: 0;
}