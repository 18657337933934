.timeline-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  /* margin-left: 50px;
  margin-right: 50px; */
  /* width: 90%; */
  /* overflow-y: hidden;
  overflow-x: hidden;
  scroll-behavior: smooth; */
  padding-bottom: 20px; /* Add some bottom padding to avoid cutting off content */
}

.timeline {
  display: flex;
  align-items: flex-start;
  /* overflow-x: hidden; */
  /* margin-right: 50px; */
  justify-content: space-between; /* Adjust to your preference */
  width: 80%;
  margin-top: 35px;
  overflow-y: hidden;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.timeline-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
  position: relative;
  /* cursor: pointer; */
  width: 100px;
}

.timeline-date {
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute;
  top: -25px; */
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
  color: #777;
  width: 100%;
}

.timeline-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  font-size: 40px;
  background-color: var(--myasu-maroon);
  border-radius: 50%;
  padding: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.timeline-icon svg {
  color: #ffffff;
}

.timeline-icon:hover {
  background-color: #ffffff;
  color: white;
}

.timeline-icon:hover svg {
  color: var(--myasu-maroon);
}

.timeline-content {
  text-align: center;
}

.timeline-content p {
  margin: 0;
}

.timeline-scroll-arrow {
  display: flex;
  /* position: absolute;
  top: 87%;
  transform: translateY(-50%); */
  margin-left: 15px;
  margin-right: 15px;
  font-size: 20px;
  color: #777;
  cursor: pointer;
  transition: color 0.3s;
}

.timeline-scroll-arrow.left {
  left: 54%;
}

.timeline-scroll-arrow.right {
  right: 70px;
}

.timeline-scroll-arrow:hover {
  color: #000;
}
