.people-container {
  position: relative;
  width: 100%;
  min-width: 100vh;
  margin-top: 60px;
  margin-left: 70px;
}

.people-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 230px;
  width: 100%;
  padding: 30px 5%;
  margin-bottom: 30px;
}

.people-container .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 230px;
  background-image: url("../../images/background_img.png");
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;
  z-index: -1;
  transform: scaleX(-1);
}

/* .people-title {
  margin-bottom: 20px;
} */

.people-title h1 {
  font-weight: bold;
  margin-left: 7px;
  margin-bottom: 0;
}

.people-title p {
  margin-top: 25px;
  margin-bottom: 0px;
  max-width: 1100px;
  color: #fff;
  font-size: 20px;
}

.people-header .title-description {
  /* margin-top: 10px; */
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 30px;
  color: #fff;
}

.people-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0px 5% 30px;
  margin-top: -15px;
}

.people-cards-container {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  flex-wrap: wrap;
  
}

.people-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 20%;
  min-width: 220px;
  margin: 20px;
  border-radius: 5px;
  cursor: pointer;
  transform: translateY(0); /* Initial position */
  transition: transform 0.3s ease; /* Smooth transition */
}

.people-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(-10px);
}

.people-card img {
  width: 100%;
  /* border-radius: 50px; */
  border-radius: 5px 5px 0 0;
}

.people-info {
  padding: 8px 16px 10px;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0; */
}

/* .people-info {
  padding: 16px;
  display: inline-block;
  position: relative;
  height: auto;
  text-align: center; 
} */

/* .people-info h4{
  
} */

.people-info p {
margin-bottom: 5px;
}

.people-info a {
  color: var(--myasu-maroon);
}
