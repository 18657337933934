.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #4f5573;
}

.login-box {
  width: 30%;
  min-width: 320px;
  max-width: 400px;
  background-color: #fff;
  padding: 20px 40px ;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  /* border-top: 10px solid rgba(236, 162, 13, 0.65); */
  border-top: 10px solid #ffc627;
  margin: 40px;
}

.login-box h2 {
  /* color: #8c1d40; */
  font-weight: 700;
  display: flex;
  font-size: 18px;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 16px;
}

.input-container {
  position: relative;
  margin-bottom: 20px;
}

.input-container input {
  height: 50px;
  width: 100%;
  padding: 12px;
  font-size: 14px;
  border: none;
  border: 1px solid rgba(22, 21, 19, 0.5);
  border-radius: 4px;
  background-color: transparent;
  transition: border-color 0.3s;
  align-items: center;
}

.input-container input:focus {
  outline: none;
  border: 1.5px solid #00688c;
}

.input-container input:focus + label {
  color: #00688c;
}

.input-container label {
  position: absolute;
  top: 14px;
  left: 12px;
  /* font-size: 14px; */
  /* font-weight: 500; */
  color: rgba(22, 21, 19, 0.65);
  pointer-events: none;
  /* transition: transform 0.3s, font-size 0.3s, color 0.3s; */
  transform: translateY(-11px);
  font-size: 11px;
  font-weight: 600;
}

/* .input-container.focused label {
  transform: translateY(-11px);
  font-size: 11px;
  font-weight: 600;
} */

.input-container.focused input {
  padding-top: 15px;
  padding-bottom: 5px;
}

button {
  display: block;
  width: 100%;
  height: 50px;
  padding: 14px;
  background-color: #1b1212;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #28282b;
}

.login-box .forgot-password-btn{
  font-size: 12px;
  color: #00688c;
  float: left;
  margin-top: 8px;
}

.login-box .register-btn{
  font-size: 12px;
  color: #00688c;
  float: right;
  margin-top: 8px;
}

.login-box .register-btn:hover,
.login-box .forgot-password-btn:hover {
  text-decoration: underline;
}

.login-image {
  width: 200px;
  height: auto;
  display: block;
  /* margin: 0 auto; */
  margin-bottom: 10px;
}

.login-footer-info {
  /* margin-top: 20px; */
  position: relative;
  top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.login-footer-info h1 {
  font-size: 18px;
}

.login-footer-info p {
  font-size: 9px;
}
