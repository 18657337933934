.edit-profile-container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    margin-top: 60px;
    margin-left: 70px;
}

.edit-profile-container .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 230px;
    background-image: url("../../images/background_img.png");
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 40%;
    transform: scaleX(-1);
    z-index: -1;
}

.edit-profile-container .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 30px 5% 0;
}

.edit-profile-container .title {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    justify-content: center;
    height: 200px;
}

.edit-profile-container .title h1 {
    font-weight: bold;
    margin-left: 7px;
    margin-bottom: 0;
}

.edit-profile-container .title p {
    margin-top: 25px;
    margin-bottom: 30px;
    max-width: 1100px;
    color: #fff;
    font-size: 20px;
}

.edit-profile-container .content-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px;
    padding: 0 5% 50px;
}

.edit-profile-container .tabs-container {
    width: 20%;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    border-bottom: 0;
}

.edit-profile-container .tab-button {
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-left: 4px solid transparent;
    background-color: #fff;
    transition: all 0.3s ease;
    text-align: left;
    color: #333;
}

.edit-profile-container .tab-button:hover {
    background-color: #f5f5f5;
}

.edit-profile-container .tab-button.active {
    font-weight: bold;
    border-left: 4px solid #8c1d40;
    background-color: #e5e5e5;
    color: #8c1d40;
}

.edit-profile-container .form-container {
    width: 60%;
}

.edit-profile-form {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}

.edit-profile-container .input-container {
    position: relative;
    margin-bottom: 20px;
}

.edit-profile-container .input-container input {
    height: 50px;
    width: 100%;
    padding: 16px 12px 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
}

.edit-profile-container .input-container label {
    font-size: 11px;
    color: #333;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s ease;
}

/* Styles for disabled fields */
.edit-profile-container .input-container.disabled input {
    background-color: #f5f5f5;
    color: #888;
    border-color: #ccc;
    cursor: not-allowed;
}

.edit-profile-container .input-container.disabled label {
    color: #888;
}


.edit-profile-container .input-container input:focus+label,
.edit-profile-container .input-container input:not(:placeholder-shown)+label {
    top: 10px;
    left: 10px;
    font-size: 11px;
    color: #00688c;
}

.edit-profile-container .form-actions {
    display: flex;
    justify-content: space-between;
}

.edit-profile-container .form-actions .MuiButton-root {
    min-width: 150px;
}

.edit-profile-container .form-actions .MuiButton-containedPrimary {
    background-color: #8c1d40;
}

.edit-profile-container .form-actions .MuiButton-outlinedSecondary {
    color: #8c1d40;
    border-color: #8c1d40;
}

/* Styling for the info icon next to New Password */
.edit-profile-container .info-icon {
    position: absolute;
    top: 14px;
    right: -28px;
    cursor: pointer;
}

.edit-profile-container .info-icon svg {
    height: 20px;
}

.edit-profile-container .info-icon:hover {
    color: #1b1212;
}

/* Styling for error messages below input fields */
.edit-profile-container .error-message {
    color: var(--myasu-error, #d32f2f);
    /* Default to red error color */
    font-size: 12px;
    margin-top: 5px;
    font-style: italic;
    background-color: #ffd2d2;
    border-radius: 4px;
    padding: 6px 8px;
    border: 1px solid var(--myasu-error, #d32f2f);
}

/* Adjustments to the input container to accommodate error messages and info icon */
.edit-profile-container .input-container {
    position: relative;
    margin-bottom: 20px;
}

.edit-profile-container .input-container input {
    padding-right: 40px;
}

/* Add extra margin below the input when error messages are present */
.edit-profile-container .input-container .error-message {
    margin-top: 8px;
    display: block;
}