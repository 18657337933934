.register-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #4f5573;
}

.register-box {
  width: 40%;
  min-width: 340px;
  max-width: 550px;
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-top: 10px solid #ffc627;
  margin: 40px;
}

.register-box h2 {
  font-weight: 700;
  display: flex;
  font-size: 24px;
  align-items: center;
  margin-bottom: 16px;
}

.register-box .name-fields {
  display: flex;
  justify-content: space-between;
}

.register-box .name-fields .input-container {
  width: 100%;
}

.register-box .name-fields .input-container input {
  width: 100%;
}

.input-container.first-name {
  margin-right: 10px;
}

.register-box .login-btn {
  font-size: 12px;
  color: #00688c;
  float: right;
  margin-top: 8px;
}

.register-box .login-btn:hover {
  text-decoration: underline;
}

.register-box .error-message {
  color: var(--myasu-error);
  background-color: #ffd2d2;
  border-radius: 4px;
  padding: 8px 10px;
  margin-top: 5px;
  margin-bottom: -5px;
  font-size: 12px;
  font-style: italic;
  border: 1px solid var(--myasu-error);
  display: block;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.register-box .warning-message {
  color: #7e610d;
  background-color: #fff3cd;
  border-radius: 4px;
  padding: 8px 10px;
  margin-top: 5px;
  margin-bottom: -5px;
  font-size: 12px;
  font-style: italic;
  border: 1px solid #7e610d;
  display: block;
  word-wrap: break-word;
  white-space: pre-wrap;
}


.register-box .info-icon {
  position: absolute;
  top: 14px;
  right: -28px;
  
}

.register-box .info-icon:hover {
  color: #1b1212;
}

.register-box .info-icon svg {
  height: 20px;
}

.input-container input,
.input-container select {
  height: 50px;
  width: 100%;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
}

.input-container select {
  padding-top: 15px;
  padding-bottom: 5px;
}

.input-container select:focus + label {
  color: #00688c;
}

.input-container select:focus {
  outline: none;
  border: 1.5px solid #00688c;
}

.input-container select option {
  padding: 10px;
  font-size: 16px;
}

.input-container .dropdown-placeholder {
  color: #999;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  pointer-events: none;
}

.input-container select:not(:placeholder-shown) + .dropdown-placeholder {
  display: none;
}

.input-container .email-container {
  display: flex;
  align-items: center;
  position: relative;
}

.email-domain {
  /* margin-left: 4px; */
  font-size: 16px;
  color: rgba(22, 21, 19, 0.65);
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 14px;
}

.input-container .email-container input {
  width: calc(100% - 100px);
}
