.chart-container {
  height: 370px;
}

.chart-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 30px;
}
