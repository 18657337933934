.keywords-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.keywords-container .container-title {
  margin-left: 20px;
  display: flex;
  justify-content: space-between;

}

/* .keywords-container .black-on-gold {
  font-size: 18px;
} */

.keywords-container .table-container {
  display: flex;
  position: relative;
}

.keywords-container .toggle-switch {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: auto 5px;
  color: var(--myasu-gray-70);
}

.toggle-switch .MuiSwitch-root {
  margin: auto 10px;
}

.toggle-switch .selected {
  color: var(--myasu-maroon);
}

.keyword-search-data {
  background-color: #f6f6f6;
  max-height: 480px;
  width: 36%;
  /* margin-right: 10px; */
  padding-right: 10px;
  /* overflow-y: auto; */
}

.keyword-search-data.full-width {
  width: 100%;
}

.react-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  /* margin-bottom: 1rem; */
}

.react-table th,
.react-table td {
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #ccc;
  position: relative;
}

.react-table th {
  font-weight: bold;
  background-color: #f6f6f6;
}

.count-badge {
  background-color: var(--myasu-maroon);
  border: 1px solid #000;
  color: #fff;
  display: inline-block;
  padding: 0.25em 0.4em;
  margin: 2px 5px;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pagination-options {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 14px;
}

.pagination-options span {
  margin-right: 0.5rem;
}

.pagination-options select {
  padding: 0.25rem;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.pagination button {
  width: 50px;
  margin-left: 0.5rem;
  padding: 0.25rem 0.5rem;
  border: none;
  background-color: #f6f6f6;
  color: #333;
  cursor: pointer;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-details {
  font-size: 14px;
  width: 100px;
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 480px;
  font-size: 1.5rem;
  color: #333;
}

.view-button {
  /* color: blue; */
  cursor: pointer;
}

.view-button:hover {
  /* color: blue; */
  cursor: pointer;
}

/* .view-button:focus {
  color: purple;
}

.view-button:visited {
  color: purple;
} */

.keyword-view-data {
  width: 64%;
  display: flex;
  max-height: 480px;
  padding-left: 10px;
  border-left: 1px solid #ccc;
  background-color: #f6f6f6;
}

.keyword-faculty-data {
  width: 50%;
  max-height: 480px;
  background-color: #f6f6f6;
  /* margin-right: 10px; */
  padding-right: 10px;
}

.keyword-side-panel {
  width: 100%;
  display: flex;
  max-height: 480px;
  /* background-color: #f6f6f6; */
}

.keyword-org-data {
  width: 50%;
  background-color: #f6f6f6;
}

.no-data-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  height: 480px;
  width: 100%;
}

.keyword-view-data .react-table th {
  min-width: 136px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  padding: 8px 10px;
  cursor: pointer;
  color: #aaa;
}

.dropdown-toggle:hover,
.dropdown-toggle.active {
  color: #333;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1;
}

.dropdown-menu a {
  text-decoration: none;
  color: #333333;
}

.dropdown-menu li {
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.university-text {
  background-color: var(--myasu-gray-10);
  color: #000;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 10px;
  font-weight: bold;
  margin-top: 5px;
}

.university-text.asu {
  background-color: var(--myasu-gold);
  color: #000;
  /* background-color: var(--myasu-maroon);
  color: #ffffff; */
}

.university-text.unlv {
  background-color: #B10202EB;
  color: #ffffff;
}

.university-text.uou,
.university-text.utah {
  color: #BE0000;
  background-color: #fff;
  border: 1px solid #ccc;
}