img.leaftet-marker-icon {
  width: auto;
}

.map-title {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 18px;
  font-weight: bold;
}
