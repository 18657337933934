.tags-container {
  position: relative;
  width: 100%;
  min-width: 100vh;
  margin-top: 60px;
  margin-left: 70px;
}

.tags-container .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 230px;
  background-image: url("../../images/background_img.png");
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;
  transform: scaleX(-1);
  z-index: -1;
}

.tags-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 270px; */
  width: 100%;
  padding: 30px 5%;
}

.tags-title {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  justify-content: center;
  height: 200px;
}

.tags-title h1 {
  font-weight: bold;
  margin-left: 7px;
  margin-bottom: 0;
}

.tags-title p {
  margin-top: 25px;
  margin-bottom: 30px;
  max-width: 1100px;
  color: #fff;
  font-size: 20px;
}

.tags-container .table-container {
  display: flex;
  position: relative;
}

.tags-main-panel {
  background-color: #f6f6f6;
  max-height: 480px;
  width: 56%;
  /* overflow-y: auto; */
}

.tags-main-panel .column-header {
  flex-direction: row;
  justify-content: space-between;
}

.tags-side-panel {
  width: 44%;
  /* display: flex; */
  flex-direction: column;
  max-height: 480px;
  background-color: #f6f6f6;
  /* min-width: 440px; */
}

.tags-side-panel .side-panel-data-container {
  /* background-color: white; */
  min-height: 348px;
  margin: 0px 20px 20px;
  font-size: 14px;
}

.tags-side-panel .side-panel-title {
  margin: 10px 0 5px;
  font-size: 16px;
  font-weight: bold;
  /* background-color: white; */
  /* width: fit-content; */
  padding: 5px 8px;
  max-height: 34px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* border-bottom: 1px solid var(--myasu-gray-30); */
}

.tags-side-panel .no-data-text {
  height: 480px;
}

.email-button-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.email-button {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 120px;
  margin: 12px 10px 10px;
  background-color: var(--myasu-maroon);
  border-radius: 20px;
}

button:hover.email-button {
  background-color: #a03152;
}

button.email-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.edit-icon,
.delete-icon,
.export-icon {
  cursor: pointer;
  color: gray;
  /* transition: color 0.1s; */
}

.edit-icon:hover,
.delete-icon:hover,
.export-icon:hover {
  color: var(--myasu-maroon);
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root:after,
.css-1rcvvn7-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid var(--myasu-maroon) !important;
}

.css-1z10yd4-MuiFormControl-root-MuiTextField-root {
  margin-top: 15px !important;
  background-color: #f6f6f6 !important;
  border-radius: 5px 5px 0 0;
  /* border: 1px solid #ccc !important; */
}

label.MuiInputLabel-root {
  padding-left: 10px;
  margin-top: -6px;
}

label.MuiInputLabel-root.MuiInputLabel-shrink {
  padding-top: 11px;
}

input.MuiInputBase-input,
textarea.MuiInputBase-input {
  padding: 3px 8px;
  min-height: 30px;
}

.MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root {
  padding-bottom: 0;
}

/* .MuiDialogActions-root{
  padding-bottom: 8px !important;
} */

button.Mui-button {
  color: var(--myasu-maroon);
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: bold;
  width: 120px;
  height: 40px;
}

button.Mui-button.save,
button.Mui-button.delete {
  color: white;
  background-color: var(--myasu-maroon);
}

button:hover.Mui-button.save,
button:hover.Mui-button.delete {
  background-color: #a03152;
}

button.Mui-button:hover {
  background-color: rgba(140, 29, 64, 0.08);
  /* border-radius: 30px; */
}

.dialog-description {
  margin-bottom: 10px !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: var(--myasu-maroon) !important;
}

.email-subject {
  width: 100%;
  border: 1px solid #ccc;
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  text-align: left;
  margin-bottom: 20px;
  font-size: 13px;
  font-family: inherit;
  color: var(--text-color);
}

.email-message {
  margin-bottom: 5px;
}

.ql-editor {
  height: 200px;
  color: var(--text-color);
  font-family: "Poppins", sans-serif;
}

.Mui-button.save,
.Mui-button.delete {
  margin-right: 16px;
}
