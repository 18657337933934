.layout {
  display: flex;
  /* width: 100%;
    box-sizing: border-box; */
}

.black-on-gold {
  box-shadow: -0.15em 0 0 #ffc627, 0.15em 0 0 #ffc627;
  background: #ffc627;
  color: #191919;
}

button.Toastify__close-button {
  width: 20px;
  height: 20px;
}

button:hover.Toastify__close-button {
  background-color: transparent;
  color: #000;
}
