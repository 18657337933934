.forgot-password-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #4f5573;
}

.forgot-password-container .info {
    /* font-weight: bold; */
    display: flex;
    font-size: 14px;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 16px;
}

.forgot-password-container .login-btn {
    font-size: 12px;
    color: #00688c;
    float: right;
    margin-top: 8px;
  }
  
  .forgot-password-container .login-btn:hover {
    text-decoration: underline;
  }