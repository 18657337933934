.faculty-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.faculty-container .title-container {
  display: flex;
  align-items: center;
}

.faculty-container .title-container .export-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -5px;
}

.faculty-container .container-title {
  margin-left: 20px;
}

.faculty-container .table-container {
  display: flex;
  position: relative;
}

.faculty-search-data {
  background-color: #f6f6f6;
  max-height: 480px;
  width: 36%;
}

.faculty-search-data.full-width {
  width: 100%;
}

.faculty-view-data {
  background-color: #f6f6f6;
  width: 64%;
  display: flex;
  flex-direction: column;
  max-height: 480px;
  overflow-y: auto;
}

/* .faculty-search-data .no-data-text {
  width: 100%;
} */

.faculty-no-data {
  background-color: #f6f6f6;
  min-height: 480px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.faculty-view-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 0;
  margin: 10px;
}

.faculty-info-details {
  display: flex;
}

.faculty-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.image-link-container {
  position: relative;
  display: inline-block;
  border-radius: 100px;
}

.image-link-container img {
  width: 130px;
  height: 130px;
  padding: 2px;
  border: 1px solid var(--myasu-gray-20);
  border-radius: 100px;
  cursor: pointer;
}

.image-link-container .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  background-color: rgba(128, 128, 128, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-link-container:hover .image-overlay {
  opacity: 1;
  border-radius: 100px;
}

.image-link-container .image-overlay-text {
  color: white;
  font-size: 14px;
  text-align: center;
}

.gs-profile-btn {
  margin-top: 10px;
  display: flex;
  height: 35px;
  width: 135px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: #ffffff;
  font-weight: bold;
  background-color: var(--myasu-maroon);
  border-radius: 32px;
}

.gs-profile-btn:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #a03152;
}

.faculty-info-container {
  margin: 0px 5px;
}

.faculty-info-container .faculty-name {
  font-size: 24px;
  font-weight: bold;
  color: var(--myasu-maroon);
}

.faculty-info-container .faculty-name:hover {
  color: var(--myasu-maroon);
}

.faculty-info-container p {
  margin-bottom: 5px;
}

.faculty-designation {
  font-style: italic;
}

.faculty-details {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.faculty-details p {
  display: flex;
  /* align-items: center; */
  margin: 0;
  font-size: 12px;
}

.faculty-details p strong {
  font-weight: bold;
  margin-right: 0.5rem;
}

.faculty-citation-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.citations-summary {
  display: flex;
  flex-direction: column;
  margin: 5px 20px 0 10px;
  min-width: 95px;
  justify-content: space-between;
  align-items: center;
}

.citations-summary .stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: flex-start; */
  margin-bottom: 10px;
}

.stats .stat-number {
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.stats .stat-description {
  font-size: 12px;
  margin: 0;
  padding: 0;
}

/* table styling */
.citations-table {
  /* margin-top: 1rem; */
  font-size: 12px;
}

.citations-table table {
  width: 100%;
  border-collapse: collapse;
}

.citations-table th,
.citations-table td {
  padding: 0.3rem;
  border-bottom: 1px solid #ddd;
}

.citations-table th {
  /* background-color: #f5f5f5; */
  font-weight: bold;
  text-align: left;
}

.citations-table td:first-child {
  border-left: none; /* Remove left border for the first column */
}

.citations-table tr:last-child td {
  background-color: #e8e8e8;
  border-bottom: none; /* Remove bottom border for the last row */
}

.citations-table tr:last-child td:first-child {
  border-top: none; /* Remove top border for the first column in the last row */
}

/* .citations-table tr:nth-child(even) {
  background-color: #f9f9f9;
} */

.faculty-view-body {
  display: flex;
  flex-direction: column;
  padding: 0px 10px 10px;
  margin: 10px;
}

.interest-topics {
  display: flex;
  width: fit-content;
  flex-wrap: wrap;
  /* background-color: #e8e8e8;
  border-radius: 20px;
  padding: 5px 10px; */
  margin-top: 3px;
  margin-bottom: -12px;
}

.interest-topic {
  font-size: 12px;
  margin-right: 5px;
  background-color: var(--myasu-gold);
  padding: 5px 10px;
  border-radius: 20px;
  margin-bottom: 8px;
}

.accordian-title {
  font-size: 20px;
}

.accordion {
  border: 1px solid #ccc;
  margin-top: 1rem;
}

/* .accordian-container {
  margin-top: 12px;
} */

.accordion-header {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: #f5f5f5;
  cursor: pointer;
}

.accordion-header:hover {
  background-color: #e8e8e8;
}

.accordion-title {
  flex-grow: 1;
  font-weight: bold;
}

.accordion-icon {
  margin-right: 0.5rem;
  font-weight: bold;
  transition: transform 0.3s ease;
}

.accordion-icon.open {
  transform: rotate(45deg);
}

.accordion-content {
  font-size: 14px;
  padding: 0.5rem;
  white-space: pre-line;
}

/* react-tag-input styles */

/* Example Styles for React Tags*/
/* .app {
  padding: 40px;
  text-align: center;
} */

/* Example Styles for React Tags*/

/* .container {
  margin: auto;
  width: 50%;
} */
.ReactTags__tags {
  position: relative;
  /* display: flex; */
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  width: 140px;
  border-radius: 2px;
  display: inline-block;
  /* padding-top: 5px; */
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 30px;
  margin: 0;
  font-size: 12px;
  border: 1px solid #ddd;
  padding-left: 4px;
  width: 100%;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  flex-wrap: wrap;
  /* padding-bottom: 5px; */
}
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #e8e8e8;
  /* background: var(--myasu-maroon); */
  /* color: white; */
  color: #333333;
  font-size: 12px;
  display: flex;
  padding: 5px 0 5px 5px;
  margin-bottom: 5px; /* change to margin- top or bottom based on inputFieldPosition*/
  /* margin: 0 4px; */
  margin-right: 5px;
  border-radius: 25px;
  height: 25px;
  width: fit-content;
  /* max-width: 80px; */
  align-items: center;
  justify-content: space-around;
}
.ReactTags__selected button.ReactTags__remove {
  display: flex;
  align-items: center;
  color: #aaa;
  padding: 5px;
  height: 25px;
  width: auto;
  margin-left: 2px;
  cursor: pointer;
}

.ReactTags__selected button:hover.ReactTags__remove {
  background-color: transparent;
  color: #000;
}



/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
  z-index: 1;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 140px;
  max-height: 160px;
  overflow-y: auto;
  padding-left: 0;
  z-index: 1;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
  padding: 0;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}

.pdf-icon {
  /* display: flex;
  align-items: center; */
  /* margin-left: 8px; */
  padding-left: 8px;
  padding-right: 8px;
  color: #aaa;
  transition: color 0.2s ease;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 10px;
}

.pdf-icon:hover,
.pdf-icon.active {
  color: #333;
}