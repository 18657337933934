.uploads-container {
  position: relative;
  width: 100%;
  min-width: 100vh;
  margin-top: 60px;
  margin-left: 70px;
}

.uploads-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 270px; */
  width: 80%;
  padding: 30px 5%;
}

.uploads-container .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 230px;
  background-image: url("../../images/background_img.png");
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 40%;
  z-index: -1;
  transform: scaleX(-1);
}

.uploads-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  height: 200px;
}

.uploads-title h1 {
  font-weight: bold;
  margin-left: 7px;
  margin-bottom: 0;
}

.uploads-title p {
  margin-top: 25px;
  margin-bottom: 30px;
  max-width: 1100px;
  color: #fff;
  font-size: 20px;
}

/* .uploads-container .table-container {
  align-items: center;
  justify-content: center;
} */

.uploads-main-panel {
  background-color: #f6f6f6;
  height: 315px;
  max-height: 480px;
  width: 100%;
  /* overflow-y: auto; */
}

.upload-box-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 10px;
}

.uploads-container .table-container{
  display: flex;
  position: relative;
  height: 315px;
}

/* .upload-box-container input{
  color: #ffffff;
} */

.upload-button {
  width: 140px;
  min-width: 120px;
  border-radius: 32px;
  background-color: var(--myasu-maroon);
}

.upload-button svg {
  margin-right: 5px;
}

.upload-button:hover {
  transform: scale(1.05);
  background-color: var(--myasu-maroon);
}

.download-button {
  cursor: pointer;
}

.download-button:hover {
  cursor: pointer;
}

.download-button.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.uploads-main-panel .no-data-text{
  height: 300px;
}

.uploads-main-panel .loading-spinner {
  height: 300px;
}

.uploads-container .dropdown-container {
  width: 440px;
  margin: 0 0 16px 20px;
}

.dropdown-container .css-13cymwt-control {
  border: 1px solid #757575;
}

.dropdown-container .css-13cymwt-control:hover {
  border: 1px solid #252525;
}

.uploads-container .dropdown-container .css-1nmdiq5-menu {
  z-index: 3;
}

.dropdown-container .css-1nmdiq5-menu input[type="checkbox"] {
  margin-right: 10px;
}

.dropdown-container .css-12a83d4-MultiValueRemove:hover {
  background-color: #FFBDAD;
  color: #8c1d40 !important;
}

.dropdown-container .css-t3ipsp-control {
  box-shadow: 0 0 0 1px #000 !important;
  border-color: #000 !important;
}

.banner.warning {
  background-color: #fffbea;
  color: #333333;
  padding: 10px 20px;
  border: 2px solid #ffe066;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}

.banner.warning span {
  margin-left: 10px;
}

.warning-icon {
  font-size: 20px;
  margin-right: 10px;
  color: #ffcc00;
}
