/* .container-title {
  font-size: 24px;
  margin-bottom: 10px;
} */
.popular-tags {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.tag-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
}

.tag-table tr {
    padding-left: 30px;
}

.tag-table th,
.tag-table td {
  padding: 8px 30px;
  /* border: 1px solid #ccc; */
}

.tag-table th {
  background-color: #f2f2f2;
  /* border: 1px solid #ccc; */
  font-weight: bold;
  text-align: left;
}

.tag-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
