.shared-topics-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.shared-topics-container .container-title {
  margin-left: 20px;
}

.shared-topics-container .table-container {
  display: flex;
  position: relative;
}

.shared-topics-main-tabs {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* height: 40px; */
  /* margin: 10px 20px; */
  width: 56%;
  font-size: 12px;
  border-bottom: 1px solid var(--myasu-gray-20);
}

.shared-topics-main-panel {
  background-color: #f6f6f6;
  max-height: 480px;
  width: 56%;
  /* overflow-y: auto; */
}

.shared-topics-side-panel {
  width: 44%;
  display: flex;
  flex-direction: column;
  max-height: 480px;
  background-color: #f6f6f6;
}

.shared-topics-tabs {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  /* height: 40px; */
  margin: 10px 20px;
  font-size: 12px;
  border-bottom: 1px solid var(--myasu-gray-20);
}

.shared-topics-tab {
  max-height: 39px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* min-width: 110px; */
  background-color: #e0e0e0;
  border-top: 1px solid var(--myasu-gray-20);
  border-left: 1px solid var(--myasu-gray-20);
  border-right: 1px solid var(--myasu-gray-20);
  border-radius: 5px 5px 0 0;
  cursor: pointer;
}

.shared-topics-tab:hover {
  background-color: var(--myasu-gray-10);
}

.shared-topics-tab.active {
  background-color: var(--myasu-maroon);
  color: white;
}

.side-panel-data-container {
  /* background-color: white; */
  min-height: 348px;
  margin: 0px 20px 20px;
  font-size: 14px;
}

.details-data {
  display: flex;
  flex-direction: column;
}

.details-data-header {
  display: flex;
  background-color: white;
  height: 90px;
  overflow-y: auto;
}

.details-org-data {
  width: 50%;
  padding: 10px 20px;
}

.details-faculty-data {
  width: 50%;
  padding: 10px 20px;
}

.details-data .data-footer {
  font-size: 11px;
  color: #747474;
}

.goals-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /* max-width: 90%; */
  padding: 0 20px;
}

.container-title.goals-title {
    margin-top: 30px;
}

.goal-image {
  width: 100px;
  height: 100px;
  padding: 2px 2px 2px 2px;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  box-sizing: border-box;
  margin: 10px 20px;
  cursor: pointer;
  transform: translateY(0); /* Initial position */
  transition: transform 0.3s ease; /* Smooth transition */
}

.goal-image:hover {
  transform: translateY(-10px); /* Move 5 pixels upwards on hover */
}

.star-icon.gold {
  color: gold;
}

.star-icon {
  cursor: pointer;
  color: gray;
  transition: color 0.2s;
}

.star-icon:hover {
  color: var(--myasu-gold);
}

.hide-icon,
.unhide-icon {
  cursor: pointer;
  color: gray;
  /* transition: color 0.1s; */
}

.hide-icon:hover,
.unhide-icon:hover {
  color: var(--myasu-maroon);
}

.hide-icon.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.shared-topics-container .no-data-text {
  height: 100%;
}

.shared-topics-container .export-icon {
  padding: 8px 10px;
  cursor: pointer;
  color: #aaa;
}

.shared-topics-container .export-icon:hover {
  color: #333;
}
