.pagenotfound-container {
    position: relative;
    width: 100%;
    height: 70vh;
    margin-top: 60px;
    margin-left: 70px;
}

.pagenotfound-container .main-container {
    align-items: center;
    justify-content: center;
    height: 100%;
}

.pagenotfound-container h1 {
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 20px;
}
.pagenotfound-container p {
    font-size: 20px;
}