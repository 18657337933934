.content-wrapper {
  /* display: flex; */
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
}

.sidebar {
  width: 250px;
  margin-top: 60px;
  height: calc(100vh - 60px);
  position: fixed;
  background-color: #f9f9f9;
  padding: 20px;
  color: #fff;
  transition: all 0.3s ease;
  border-right: 1px solid var(--myasu-gray-20);
  z-index: 999;
  /* box-sizing: border-box; */
}

.sidebar.collapsed {
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  /* display: flex; */
  flex: 1; /* Make the container take up remaining width */
  margin-top: 60px;
  margin-left: 70px;
  margin-right: 0;
  padding: 0;
  position: relative;
  width: 100vw;
  box-sizing: border-box;
}

.nav {
  display: flex;
  flex-direction: column;
}

.nav.collapsed {
  align-items: center;
}

.nav-link {
  display: flex;
  align-items: center;
  color: var(--text-color);
  text-decoration: none;
  margin-bottom: 15px;
  padding: 6px;
  transition: all 0.3s ease;
}

.nav-link:hover {
  color: var(--text-color);
}

.nav-link svg {
  color: var(--myasu-gray-80);
}

.nav-link .collapsed-icon svg {
  width: auto;
  height: 25px;
}

.nav-link .expanded-icon svg {
  justify-content: center;
  margin-right: 8px;
  width: auto;
  height: 18px;
}

/* .nav-link .expanded-icon.about svg {
  margin-left: 4px;
  margin-right: 12px;
} */

.nav-link span {
  display: inline-block;
  margin-left: 5px;
}

.toggle-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: right;
  font-size: 20px;
  padding: 6px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  color: var(--myasu-gray-80);
  height: auto;
}

.toggle-button svg {
  width: auto;
  height: 25px;
}

.toggle-button:hover {
  color: var(--myasu-maroon);
  border-radius: 4px;
  background-color: transparent;
}

.sidebar.collapsed .toggle-button {
  justify-content: center;
}

.sidebar.collapsed .toggle-button svg {
  transform: rotate(180deg);
}

.collapsed-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.expanded-icon {
  display: flex;
  align-items: center;
}

.collapsed-icon span {
  font-size: 12px;
  margin-top: 4px;
  margin-left: 0;
  max-width: 60px;
  word-wrap: break-word;
  text-align: center;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
}

.expanded-icon span {
  padding-top: 3px;
}

.sidebar .collapse {
  display: block !important;
}

.collapsed-icon:hover,
.expanded-icon:hover,
.collapsed-icon:hover svg,
.expanded-icon:hover svg,
.nav-link.active,
.nav-link.active svg {
  color: var(--myasu-maroon);
}
