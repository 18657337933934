.image-container {
  position: relative;
  display: inline-block;
}

.image-find-1 {
  display: block;
  max-width: 100%;
  padding: 0 30px;
  height: 250px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.image-text {
  margin-left: 5px;
  position: absolute;
  top: 77%;
  left: 40%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.details-container {
  padding: 0 30px;
  max-height: 120px;
  overflow: hidden;
}

.details-text {
  color: #000000;
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Limit to 4 lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.view-case-studies-button {
  height: 30px;
  width: auto;
  background-color: var(--myasu-maroon);
  color: white;
  margin-top: 8px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-case-studies-button:hover {
    background-color: #a03152;
}
