.reset-password-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #4f5573;
}

.reset-password-container .info {
    /* font-weight: bold; */
    display: flex;
    font-size: 14px;
    /* justify-content: center; */
    align-items: center;
    margin-bottom: 16px;
}

.reset-password-container .login-btn {
    font-size: 12px;
    color: #00688c;
    float: right;
    margin-top: 8px;
}

.reset-password-container .login-btn:hover {
    text-decoration: underline;
}

.reset-password-container .error-message {
    color: var(--myasu-error);
    background-color: #FFD2D2;
    border-radius: 4px; 
    padding: 8px 10px;
    margin-top: 5px;
    margin-bottom: -5px;
    font-size: 12px;
    font-style: italic;
    border: 1px solid var(--myasu-error);
    display: block;
    /* max-width: 90%; */
    word-wrap: break-word;
    white-space: pre-wrap;
  }